import { onLoad, els, validateEmail } from './util/util';

onLoad(() => {
    try {
        const inputContainers = els('.jls-input');
        for (const i of inputContainers) {
            const inputField = i.querySelector('input');
            const textareaField = i.querySelector('textarea');
            const requiredError = i.querySelector('.error-container.required');
            const emailError = i.querySelector('.error-container.email');

            if(inputField) {
                //check on first load if there is content
                onLoadCheckIfContent(inputField);
                inputField.addEventListener("focus", e => onFocus(inputField));
                inputField.addEventListener("focusout", e => onFocusOut(inputField, requiredError, emailError));
                inputField.addEventListener("input", e => handleOnInput(inputField, requiredError, emailError));
            }
            if(textareaField) {
                //check on first load if there is content
                onLoadCheckIfContent(textareaField);
                textareaField.addEventListener("focus", e => onFocus(textareaField, requiredError, emailError));
                textareaField.addEventListener("focusout", e => onFocusOut(textareaField, requiredError, emailError));
                textareaField.addEventListener("input", e => handleOnInput(textareaField, requiredError, emailError));
            }
        }
    } catch (err) {
        console.error('Error initializing inputfields', err);
    }
});

function onFocusOut(item, requiredError, emailError) {
    const label = item.previousSibling.previousSibling;
    handleInputErrorOnFocusOut(item, label, requiredError, emailError);
    if(!item.value &&  label) {
        item.previousSibling.previousSibling.classList.remove('move-up');
    }
}

function onFocus(item) {
    const label = item.previousSibling.previousSibling;
    if(item.value) {
        label.classList.remove('label-error');
    }
    if (label) {
        item.previousSibling.previousSibling.classList.add('move-up');
    }
}

function onLoadCheckIfContent(item) {
    const label = item.previousSibling.previousSibling;
    if (label && item.value) {
        item.previousSibling.previousSibling.classList.add('move-up');
    }
}

export function handleInputErrorOnFocusOut(item, label, requiredError, emailError) {

    // check email
    if(item.type === "email" && !validateEmail(item.value)) {
        label.classList.add('label-error');
        item.classList.add('input-error');
        emailError.classList.remove('hidden');
        return;
    } else {
        label.classList.remove('label-error');
        item.classList.remove('input-error');
        emailError.classList.add('hidden');
    }

    // check required fields
    if(item.required && !item.value) {
        label.classList.add('label-error');
        item.classList.add('input-error');
        requiredError.classList.remove('hidden');
    } else {
        label.classList.remove('label-error');
        item.classList.remove('input-error');
        requiredError.classList.add('hidden');
    }
}

function handleOnInput(item, requiredError, emailError) {
    const label = item.previousSibling.previousSibling;

    // remove error as soon as validateEmail is satisfied
    if(item.type === "email" && validateEmail(item.value)) {
        emailError.classList.add('hidden');
    }

    // remove error as soon as something is typed in
    if(item.required && item.value) {
        label.classList.remove('label-error');
        item.classList.remove('input-error');
        requiredError.classList.add('hidden');
    }
    
}

