import {els, onLoad, selectElementFromParent} from "./util/util";

// implements reference filtering that does not rely on performing navigation
onLoad(() => {
    const dropDowns = els("div.dropdown-container");
    if (dropDowns.length === 0) {
        // nothing to do if no dropdown present
        return;
    }
    const referenceTiles = els("div.reference-tile");
    const dropDownButtonLabels = els("button.dropdown-button span.selected-filter");
    const dropDownListItems = els("div.dropdown-container div.dropdown-list ul li.reference-filter-item");
    const filterAnchors = Array.from(dropDownListItems).map((item) => {return selectElementFromParent(item, "a")});

    // select all internal links (links used for filtering the references) and preventDefault.
    // instead, capture the href, extract the category string and inform all necessary elements of the selection through
    // a custom event.
    filterAnchors.forEach((a) => {
        a.addEventListener('click', (event) => {
            // preventDefault to not actually pursue the href and trigger a navigation
            event.preventDefault();

            const href = event.target.getAttribute("href");
            if (!href) {
                return;
            }

            let category = href.split("=")[1];
            if (!category) {
                // href present, but not query parameter -> means filter was set to "all"
                category = "";
            }

            // communicate selection to all reference tiles
            referenceTiles.forEach((rt) => {
                rt.dispatchEvent(new CustomEvent('referenceFiltered', {detail: category}));
            })
            // communicate filter selection to dropdown button
            dropDownButtonLabels.forEach((label) => {
                label.dispatchEvent(new CustomEvent('referenceFiltered', {detail: category}));
            })
            // communicate filter selection to filter list items
            dropDownListItems.forEach((li) => {
                li.dispatchEvent(new CustomEvent('referenceFiltered', {detail: category}));
            })
        })
    })


    // build a map of regex-parsed (as received through anchor click) category to full category string as used in labels
    // and defined on the 'data-offer-categories' attribute of the individual reference-tiles.
    const fullCategoryList = [];
    referenceTiles.forEach((rt) => {
        // build full list of categories
        const categoryString = rt.getAttribute("data-offer-categories");
        const categorySubStrings = categoryString.split("_");
        categorySubStrings.forEach((sstr) => {
            if (!fullCategoryList.includes(sstr)) {
                fullCategoryList.push(sstr);
            }
        })
    })
    const categoryRegexMap = {};
    fullCategoryList.forEach((cat) => {
        categoryRegexMap[`${cat.replace(/[\W_]+/g, "")}`] = cat;
    })
    // enforce "all" category string
    const dropDownButton = els("button.dropdown-button")[0];
    categoryRegexMap[""] = dropDownButton.getAttribute("data-all-text");


    // set the correct filter-string on the dropdown button
    dropDownButtonLabels.forEach((label) => {
        label.addEventListener("referenceFiltered", (event) => {
            label.textContent = categoryRegexMap[event.detail];
        })
    })


    // ensure the dropdown list reflects current filter-state -> remove currently used filter from list, ensure "all"
    // option is present if having some other filter applied
    dropDownListItems.forEach((li) => {
        li.addEventListener("referenceFiltered", (event) => {
            if (categoryRegexMap[event.detail] === li.textContent) {
                li.classList.add("hidden");
            } else {
                li.classList.remove("hidden");
            }
        })
    })



    // enact filtering based on 'data-offer-categories' attribute
    referenceTiles.forEach((rt) => {
        // listen to incoming custom event that signifies a change of the reference filter setting
        rt.addEventListener("referenceFiltered", (event) => {
            // grab categories as defined in data-offer-categories, and check if the full category string that matches
            // the new filter's "unparsed" value from the map. If filter matches, or filter is set to all, then show.
            const categoryString = rt.getAttribute("data-offer-categories");
            if (categoryString.includes(categoryRegexMap[event.detail]) || event.detail==="") {
                rt.classList.remove("hidden");
            } else {
                rt.classList.add("hidden");
            }
        })
    })
})
