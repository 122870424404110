// import "./styles/tailwind.css";
// import "./styles/fonts.css";
// import "./styles/variables.scss";
import "./styles/styles.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
import { onLoad } from "./js/util/util";

import "./js/navigation";
import "./js/header-video";
import "./js/gsap/main-gsap";
import "./js/image-slider";
import "./js/product-slider";
import "./js/contact-cta";
import "./js/input-fields";
import "./js/form";
import "./js/checkbox-with-text";
import "./js/reference-tiles";
import "./js/links"
import "./js/contact-overlay";

// is important that this css import stays here to apply changes to product slider
import "./styles/extras.css";
// import "./styles/inputFields.scss";
// import "./styles/cookie.scss";
import Cookie from "./js/cookie";

onLoad(() => {
    const cookie = new Cookie();

    cookie.initializeCookie();
});
