import { gsap } from "gsap";

export function animateDropdownFilter() {
  const dropdownArrow = document.querySelector(".dropdown-arrow");
  const dropdownContainer = document.querySelector(".dropdown-container");
  const dropdownList = document.querySelector(".dropdown-list");
  const dropdownToggle = document.querySelector(".dropdown-button");
  const selectedFilter = document.querySelector(".selected-filter");
  const tiles = document.querySelectorAll(".reference-tile");

  if (!(dropdownArrow && dropdownContainer && dropdownList && dropdownToggle && selectedFilter && tiles.length)) {
      return;
  }

  //Filter Tiles
  // TODO: when continuing with JW-195, check if this filter here is really needed, or if it is in fact (as I expect)
  //  messing with the filtering done in "../reference-tiles.js". If so, and this can be cleaned up, then rename the file
  //  and make it a generic animateDropdown function (i.e., unify classes and functionality with those of the
  //  footer dropdown macro introduced with JW-200
  let filterValue = selectedFilter.dataset.filterValue;
  tiles.forEach((tile) => {
    if (filterValue === "") {
      tile.classList.remove("hidden");
    } else {
      const tileCategories = tile.dataset.offerCategories;
      if (!tileCategories || !tileCategories.includes(filterValue)) {
        tile.classList.add("hidden");
      } else {
        tile.classList.remove("hidden");
      }
    }
  });

  //Dropdown List Styling
  let dropdownIsOpen = false;
  let dropdownListHeight;
  updateDropdownListHeight();
  window.addEventListener("resize", updateDropdownListHeight);

  //Handle Click on Dropdown
  dropdownToggle.onclick = (event) => {
    event.stopPropagation();
    if (dropdownIsOpen) {
      closeDropdown();
    } else {
      openDropdown();
    }
  }

  window.addEventListener("click", () => {
    if (dropdownIsOpen) {
      closeDropdown();
    }
  });

  function closeDropdown() {
    gsap.timeline()
      .addLabel("start")
      .to(dropdownList, { duration: 0.1, y: -dropdownListHeight, ease: "power2.in" })
      .to(dropdownArrow, { duration: 0.1, rotate: 0 }, "start")
      .add( () => { dropdownIsOpen = false });
  }

  function openDropdown() {
    gsap.timeline()
      .addLabel("start")
      .to(dropdownList, { duration: 0.1, y: 0, ease: "power2.out"  })
      .to(dropdownArrow, { duration: 0.1, rotate: 180 }, "start")
      .add(() => { dropdownIsOpen = true });
  }

  function updateDropdownListHeight() {
    if (dropdownIsOpen) {
      closeDropdown();
    }
    dropdownListHeight = dropdownList.clientHeight + 50;

    gsap.set(dropdownContainer, { height: dropdownListHeight });
    gsap.set(dropdownList, { y: -dropdownListHeight });
  }
}
